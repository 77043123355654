<template>
  <div class="cut-msg">
    <h4>Релевантные сообщения</h4>
    <table class="table mb-0 table-center text-secondary" style="font-size:small; width:100%; table-layout:fixed;"
      rules="groups">
      <colgroup>
        <col span="1" style="width:90%;">
        <col span="1" style="width:5%;">
      </colgroup>
      <tbody>
        <template v-for="txt in texts">
          <tr style="background-color: #1f2d3d !important; color: #9cb2da !important;">
            <td style="word-wrap: break-word">{{ txt }}</td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: ["texts"],
  components: {},
  data() {
    return {};
  },
}
</script>

<style>
div.cut-msg {
  color: #9cb2da
}

.span {
  display: block
}
</style>
